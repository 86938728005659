
import React, { Component, Fragment, useEffect,useState,useRef } from 'react';
// import React, { useState, useEffect, useMemo, useRef } from 'react';
// import NavBar from '../NavBar/NavBar';
import './Home.css'
import { useHistory } from "react-router-dom";
import $ from 'jquery';
import Sidebar from '../Template/Sidebar/Sidebar';
import Header from '../Template/Header/Header';
import DatePicker from "react-datepicker";
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import "react-datepicker/dist/react-datepicker.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { formatNumberWithToFixed, roundToFour, roundToTwo,replaceNaNInStrings } from '../../components/reports/ProfitabilityDiagnosis/common/commanFn';
import { baseUrl } from '../../config';
import axios from 'axios';
import Loader from 'react-loader-spinner';
// import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,annotations } from 'chart.js';
//import ChartAnnotation from 'chartjs-plugin-annotation';
// import 'chartjs-plugin-annotation';
import ChartAnnotation from 'chartjs-plugin-annotation';
import annotationPlugin from "chartjs-plugin-annotation";

// import { ChartAnnotation } from 'chartjs-plugin-annotation';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartAnnotation,
  annotationPlugin
);


function HomePage(props) {
  const history = useHistory();
  var i=0;
  const userdata=JSON.parse(localStorage.getItem('userdata'));
  const homeUserName=userdata?.username;
  const handleSignOut = () => {
    console.log('logout')
    window.localStorage.setItem('isLogin',false);
    history.push('/');
  }

  const [welcomeMsg, setWelcomeMsg] = useState(`Welcome ${homeUserName} to Clearer Data`); 

  if(localStorage.getItem('isLogin')==="false" || localStorage.getItem('isLogin')==null){
    history.push('/');
  }
    
  const [REFCB, setREFCB] = useState(true);
  const [Refund, setRefund] = useState('9');
  const [CB, setCB] = useState('1');
  const REFCBCheckBox = useRef();

  const [startDate, setStartDate] = useState(new Date(Date.now()).setHours(0, 0, 1, 1));//useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  //useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  
  const [endDate, setEndDate] = useState(new Date(Date.now()).setHours(23, 59, 59, 0));
  
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [reportData, setreportData] = useState([])
  const [profitData, setProfitData] = useState([])
  const [declineData, setDeclineData] = useState([])

  //***** Graph work  *******/
  const [numDataPoints, setNumDataPoints] = useState(5); // Default to 5 data points
  const [rangeType, setRangeType] = useState('daily'); // Default to daily range
  const [chartData, setChartData] = useState(null);
  const [chartDataRP, setChartDataRP] = useState(null);
  const [chartDataM, setChartDataM] = useState(null);
  const [graphDate, setGraphDate] = useState(new Date(Date.now()).setHours(0, 0, 1, 1));//useState(new Date(new Date().setDate(new Date().getDate() - 30)));


  const [REFCBG, setREFCBG] = useState(true);
  const [RefundG, setRefundG] = useState('9');
  const [CBG, setCBG] = useState('1');
  const REFCBCheckBoxG = useRef();

  const [loaderG, setLoaderG] = useState(false)

  const [showGraph1, setShowGraph1] = useState(true);
  const [showGraph2, setShowGraph2] = useState(true);
  const [showGraph3, setShowGraph3] = useState(true);

  const handleChangeNumber = (e) => {
    let value = e.target.value;
    value = value === '' ? '' : Math.max(1, Math.min(25, Number(value))); // Ensure min and max constraints
    
    setNumDataPoints(value);
  };


  
  const generateDateRanges = (numDataPoints, rangeType) => {
    const dateRanges = [];
    const today = moment();

    for (let i = 0; i < numDataPoints; i++) {
      let startDate, endDate;

      switch (rangeType) {
        case 'daily':
          startDate = today.clone().subtract(i, 'days').startOf('day');
          endDate = today.clone().subtract(i, 'days').endOf('day');
          break;
        case 'weekly':
          startDate = today.clone().subtract(i, 'weeks').startOf('week');
          endDate = today.clone().subtract(i, 'weeks').endOf('week');
          break;
        case 'monthly':
          startDate = today.clone().subtract(i, 'months').startOf('month');
          endDate = today.clone().subtract(i, 'months').endOf('month');
          break;
        default:
          break;
      }

      dateRanges.push({
        start: startDate.format('MMM D, YYYY'),
        end: endDate.format('MMM D, YYYY'),
      });
    }

    return dateRanges;
  };


  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Orders: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date Range',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        // border:{dash: [4, 4]},
        title: {
          display: true,
          text: 'Order Count',
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y',
              value: 0, // The Y value where the line should appear
              borderColor: 'gray',
              borderWidth: 2,
              borderDash: [5, 5], // Dashed line style
              label: {
                content: 'Y = 0',
                enabled: false, // Disable the label
              },
            },
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      //   grid: {
      //     color: '#aaa', // for the grid lines
      //     tickColor: '#000', // for the tick mark
      //     tickBorderDash: [2, 3], // also for the tick, if long enough
      //     tickLength: 10, // just to see the dotted line
      //     tickWidth: 2,
      //     offset: true,
      //     drawTicks: true, // true is default 
      //     drawOnChartArea: true // true is default 
      // },
      //   beginAtZero: true,
      },
    },
  };

  // const optionsTemp = {
  //   responsive: true,
  //   plugins: [
  //     {
  //       legend: {
  //         position: 'top',
  //       },
  //       title: {
  //         display: true,
  //         text: '',
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: function (context) {
  //             return `Revenue: ${context.raw}`;
  //           },
  //         },
  //       },
  //     },{
  //       legend: {
  //         position: 'top',
  //       },
  //       title: {
  //         display: true,
  //         text: '',
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: function (context) {
  //             return `Profit: ${context.raw}`;
  //           },
  //         },
  //       },
  //     },
  //   ],
  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: 'Date Range',
  //       },
  //       beginAtZero: true,
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: 'Revenue & Profit',
  //       },
  //       beginAtZero: true,
  //     },
  //   },
  //   annotation: {
  //     annotations: {
  //       line1: {
  //         type: 'line',
  //         mode: 'horizontal',
  //         scaleID: 'y',
  //         value: 0, // The Y value where the line should appear
  //         borderColor: 'gray',
  //         borderWidth: 2,
  //         borderDash: [5, 5], // Dashed line style
  //         label: {
  //           content: 'Y = 0',
  //           enabled: false, // Disable the label
  //         },
  //       },
  //     },
  //   },
  // };

  const options = {
    responsive: true,
    // layout: {
    //   padding: {
    //     top: 0,     // padding at the top of the chart
    //     left: 0,    // padding at the left of the chart
    //     right: 0,   // padding at the right of the chart
    //     bottom: 0,  // padding at the bottom of the chart
    //   }
    // },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
      },
      // Add the annotation plugin here
      annotation: {
        annotations: {
          line1: {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y',
            value: 0, // The Y value where the line should appear
            borderColor: 'gray',
            borderWidth: 2,
            borderDash: [5, 5], // Dashed line style
            label: {
              content: 'Y = 0',
              enabled: false, // Disable the label
            },
          },
        },
      },
    },
    plugins1:[
      {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `Revenue: ${context.raw}`;
            },
          },
        },
        // Add the annotation plugin here
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y',
              value: 0, // The Y value where the line should appear
              borderColor: 'gray',
              borderWidth: 2,
              borderDash: [5, 5], // Dashed line style
              label: {
                content: 'Y = 0',
                enabled: false, // Disable the label
              },
            },
          },
        },
      },
      {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `Profit: ${context.raw}`;
            },
          },
        },
        // Add the annotation plugin here
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y',
              value: 0, // The Y value where the line should appear
              borderColor: 'gray',
              borderWidth: 2,
              borderDash: [5, 5], // Dashed line style
              label: {
                content: 'Y = 0',
                enabled: false, // Disable the label
              },
            },
          },
        },
      }
    ],
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date Range',
        },
        beginAtZero: true,
      },
      y: {
        title: {
          display: true,
          text: 'Revenue & Profit',
        },
        beginAtZero: true,
      },
    },
  };
  
  // const chartOptions1 = {
  //   responsive: true,
  //   plugins: [
  //     {
  //       title: {
  //         display: true,
  //         text: '',
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: function (context) {
  //             return `Revenue: ${context.raw}`;
  //           },
  //         },
  //       },
  //     },{
  //       title: {
  //         display: true,
  //         text: '',
  //       },
  //       tooltip: {
  //         callbacks: {
  //           label: function (context) {
  //             return `Profit: ${context.raw}`;
  //           },
  //         },
  //       },
  //     },
  //   ],
  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: 'Date Range',
  //       },
  //       ticks: {
  //         autoSkip: true,
  //         maxRotation: 45,
  //         minRotation: 45,
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: 'Revenue & Profit',
  //       },
  //       beginAtZero: true,
  //     },
  //   },
  //   // Adding a dashed line at Y=0
  //   // annotation: {
  //   //   annotations: {
  //   //     line: {
  //   //       type: 'line',
  //   //       yMin: 0,    // Y-axis position at 0
  //   //       yMax: 0,    // Keep it at 0 for a horizontal line
  //   //       borderColor: 'grey',
  //   //       borderWidth: 1,
  //   //       borderDash: [5, 5], // Dashed pattern [length, gap]
  //   //       label: {
  //   //         content: 'Zero Line',
  //   //         enabled: true,
  //   //         position: 'center',
  //   //         font: {
  //   //           size: 10,
  //   //         },
  //   //         color: 'grey',
  //   //       },
  //   //     },
  //   //   },
  //   // },
  // };
  const chartOptions2 = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Margin: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date Range',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      // y: {
      //   // border:{dash: [6, 6]},
      // //   grid: {
      // //     color: red, // for the grid lines
      // //     // tickColor: '#000', // for the tick mark
      // //     // tickBorderDash: [2, 3], // also for the tick, if long enough
      // //     // tickLength: 5, // just to see the dotted line
      // //     // tickWidth: 1,
      // //     // offset: true,
      // //     // drawTicks: true, // true is default 
      // //     // drawOnChartArea: true // true is default 
      // // },
      //   title: {
      //     display: true,
      //     text: 'Margin',
      //   },
      //   beginAtZero: true,
      // },
      y: {
        border:{dash: [6, 6]},
        title: {
          display: true,
          text: 'Margin',
        },
        beginAtZero: true,
      },
    },
    // annotation: {
    //   drawTime: "afterDatasetsDraw", // (default)
    //   events: ["click"],
    //   dblClickSpeed: 350, // ms (default)
    //   annotations: [
    //     {
    //       drawTime: "afterDraw",
    //       id: "a-line-1",
    //       type: "line",
    //       mode: "horizontal",
    //       scaleID: "y-axis-0",
    //       value: "25",
    //       borderColor: "red",
    //       borderWidth: 2
    //     }
    //   ]
    // }
  };
  

  



  const fetchApi = async () => {


    if (startDate == null) {
      setMessage('Start Date must not be blank')
      return false;
    } if (endDate == null) {
      setMessage('End Date must not be blank')
      return false;
    } else {
      setMessage('')
    }

  
    let API_URL = '';
    setLoader(true);
    API_URL = baseUrl + `/reports/home/` + '?startdate=' + moment(startDate).format('YYYY-MM-DD HH:mm') + "&enddate=" + moment(endDate).format('YYYY-MM-DD HH:mm:ss') + "&refund=" + Refund + "&cb=" + CB + "&refcb=" + REFCB
    
    try {
      const response = await axios.get(API_URL, {});
      
      if (response.data.result.profitview.length > 0) {
        response.data.result.profitview.forEach((i, j) => {
          i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString();
          i.AOV = "$" + formatNumberWithToFixed(i.AOV).toLocaleString();
          i.cpa_cost = "$" + formatNumberWithToFixed(i.cpa_cost).toLocaleString();
          i.cpa_cost_per_order = "$" + formatNumberWithToFixed(i.cpa_cost_per_order).toLocaleString();
          i.cogs = "$" + formatNumberWithToFixed(i.cogs).toLocaleString();
          i.cogs_per_order = "$" + formatNumberWithToFixed(i.cogs_per_order).toLocaleString();
          i.refund_dollars_amount = "$" + formatNumberWithToFixed(i.refund_dollars_amount).toLocaleString();
          i.chargebacks_dollars_amount = "$" + formatNumberWithToFixed(i.chargebacks_dollars_amount).toLocaleString();
          i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
          i.shipping_cost = "$" + formatNumberWithToFixed(i.shipping_cost).toLocaleString();
          i.shipping_cost_per_order = "$" + formatNumberWithToFixed(i.shipping_cost_per_order).toLocaleString();
          i.profit_margin = roundToTwo(i.profit_margin * 100) + "%";
          i.chargebacks_dollars_percent = roundToTwo(i.chargebacks_dollars_percent * 100) + "%";
          i.chargebacks_rate_percent = roundToTwo(i.chargebacks_rate_percent * 100) + "%";
          i.refund_dollars_percent = roundToTwo(i.refund_dollars_percent * 100) + "%";
          i.refund_rate_percent = roundToTwo(i.refund_rate_percent * 100) + "%";
          i.total_order=i.total_order || 0;
          i.number_of_refunds=i.number_of_refunds || 0;
          i.number_of_chargebacks=i.number_of_chargebacks || 0;
          console.log(i);
          i=replaceNaNInStrings(i);
          response.data.result.profitview[j]=i;
          console.log(i)
         
        });
        setreportData(response.data.result);
        setProfitData(response.data.result.profitview)
        setDeclineData(response.data.result.decline)
        setLoader(false)
      }else{
        setreportData([]);
        setLoader(false)
      }
    } catch (e) {console.log(e.message)
      setLoader(false)
      setMessage('Something went wrong');
    }
  }

  const runGraph = async()=>{
    setLoaderG(true);
    //return false;
    let API_URL=''
    API_URL = baseUrl + `/reports/home/graph` +'?date=' + moment(graphDate).format('YYYY-MM-DD HH:mm') + "&refund=" + RefundG + "&cb=" + CBG + "&refcb=" + REFCBG+"&points="+numDataPoints+"&range="+rangeType
    const response = await axios.get(API_URL, {});
    if (response.data.result.length > 0) {
      response.data.result.forEach((i, j) => {
        i.total_revenue_v = roundToFour(i.total_revenue);
        i.total_revenue = "$" + formatNumberWithToFixed(i.total_revenue).toLocaleString(); 
        i.net_profit_v = roundToFour(i.net_profit)
        i.net_profit = "$" + formatNumberWithToFixed(i.net_profit).toLocaleString();
        i.profit_margin_v = roundToTwo(i.profit_margin * 100);
        i.profit_margin = roundToTwo(i.profit_margin * 100) + "%";
        console.log(i);
      });
      let reportData=response.data.result;
      let tempData = {
      
        labels: reportData.map(range => `${range.date}`),
        datasets: [
          {
            label: 'Order', // You can change this for different stats
            data: reportData.map((item)=>item.total_order),
            borderColor: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            tension: 0.4,
          },
        ],
      };
      let tempDataRP = {
        // labels: reportData.map(range => `${range.start_date} - ${range.end_date}`),
        labels: reportData.map(range => `${range.date}`),
        datasets: [
          {
            label: 'Revenue', // You can change this for different stats
            data: reportData.map((item)=>item.total_revenue_v),
            borderColor: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            tension: 0.4,
          },
          {
            label: 'Profits', // You can change this for different stats
            data: reportData.map((item)=>item.net_profit_v),
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(75, 192, 192, 1)',
            tension: 0.4,
          },
        ],
      };

      let tempDataM = {
        // labels: reportData.map(range => `${range.start_date} - ${range.end_date}`),
        labels: reportData.map(range => `${range.date}`),
        datasets: [
          {
            label: 'Profit Margin', // You can change this for different stats
            data: reportData.map((item)=>item.profit_margin_v),
            borderColor: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            borderWidth: 1,
            pointRadius: 5,
            pointBackgroundColor: 'rgba(0, 0, 0, 1)',
            tension: 0.4,
          },
        ],
      };
       setChartData(tempData);
       setChartDataRP(tempDataRP);
       setChartDataM(tempDataM);
      setLoaderG(false)
    }else{
      setLoaderG(false)
    }
  }

  useEffect(() => {
    fetchApi();
    //runGraph();
  }, [])

  const PrettoSlider = styled(Slider)({
    color: '#337ab7',
    height: 8,
    position: 'absolute',
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 10,
      background: 'unset',
      padding: 0,
      width: 25,
      height: 25,
      zIndex: -10,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#337ab7',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });
  return (
    <div id="wrapper" className>
      <Sidebar/>
      <Header handleSignOut={handleSignOut} welcomeMsg={welcomeMsg}/>
      <section id="content-wrapper">
      {loader && 
      <div className='loaderDiv loaderGraph'>
      <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    }
      <div>
      <div className="row bs-linebreak">
      <div className="col-lg-16">
      <div className="col-sm-3"></div>
      <div className="col-sm-10">
        <label className='homeReportTitle'>Summary Report for Sales Performance</label>
        </div>
        <div className="col-sm-3"></div>
        </div>
      </div>


      <div className="row bs-linebreak"></div>
      


      <div className="row">
        
      <div className="col-lg-16">
      {/* <div class="col-sm-2" ><b>Summary Report for Sales Performance</b></div> */}
      <div class="col-sm-10" >
      
      <div className='FilterDivSection'>
          <label className='startDateStyle_home'>Start Date</label>
          <label className='endDateStyle_home'>End Date</label>
          {!REFCB ? '' :
            <label className='refundStyle_home'>Refund Percent</label>
          }
          {!REFCB ? '' :
            <label className='chargebackStyle_home'>CB Percent</label>
          }

          <div className="input-bar">
         
            
            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={startDate}
                className='width142x'
                onChange={(date) => setStartDate(date)}
                placeholder='Start Date'
              />

            </div>

            &nbsp;&nbsp;&nbsp;

            <div className="input-bar-item width100">

              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={endDate}
                className='width142x'
                onChange={(date) => setEndDate(date)}
                placeholder='End Date'
              />

            </div>
            &nbsp;&nbsp;&nbsp;

            <div className="checkboxDiv">
              <FormGroup>
                <FormControlLabel control={<Checkbox
                  ref={REFCBCheckBox}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                  name='Estimate Refunds/CBs'
                  value={REFCB}
                  defaultChecked={REFCB}
                  onChange={() => setREFCB(!REFCB)}
                />}
                  label="Estimate Refunds/CBs" />
              </FormGroup>
            </div>

            {!REFCB ? '' :

              <div className="input-bar-item">


                <Box width={150}></Box>

                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={Refund}
                  onChange={e => setRefund(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            {!REFCB ? '' :

              <div className="input-bar-item">



                {/* <Box sx={{ m: 3 }} /> */}
                <Box width={150}></Box>
                <PrettoSlider

                  aria-label="pretto slider"
                  defaultValue={0}
                  min={0}
                  max={15}
                  value={CB}
                  onChange={e => setCB(e.target.value)}
                  step={1}
                  valueLabelDisplay="on"
                  marks
                />
              </div>
            }

            <div className="input-bar-item">
              <button type="button" onClick={fetchApi} className="btn btn-primary">Run Report</button>

            </div>
  
          </div>

      </div>
      
      <div class="col-sm-4" ></div>
      </div>
      <div class="col-sm-4" ></div>
      </div>
      </div>

     
   
        <div className="row reportMainHome">
          <div className="col-lg-16 homeReportRow header">
            <div class="col-sm-2" ><span className='mr30'>Orders</span></div>
            <div class="col-sm-2" >Revenue</div>
            <div class="col-sm-2" >AOV</div>
            <div class="col-sm-2" >Profits</div>
            <div class="col-sm-2" >Margin</div>
            <div class="col-sm-2" >CPA.Total</div>


          </div>

          <div className="row">
            <div className="col-lg-12 homeReportRow">
              <div class="col-sm-2" ><span className='mr30'>{profitData[0]?.total_order}</span></div>
              <div class="col-sm-2" >{profitData[0]?.total_revenue}</div>
              <div class="col-sm-2" >{profitData[0]?.AOV}</div>
              <div class="col-sm-2" >{profitData[0]?.net_profit}</div>
              <div class="col-sm-2" >{profitData[0]?.profit_margin}</div>
              <div class="col-sm-2" >{profitData[0]?.cpa_cost}</div>
            </div>

            <div className="row">
              <div className="col-lg-12 homeReportRow header">
                <div class="col-sm-2" >COGS.Total</div>
                <div class="col-sm-2" >Shipping.Total</div>
                <div class="col-sm-2" >Refunds.Count</div>
                <div class="col-sm-2" >Refunds.Total</div>
                <div class="col-sm-2" >Chargebacks.Count</div>
                <div class="col-sm-2" >Chargebacks.Total</div>
              </div>

            </div>

            <div className="row">
              <div className="col-lg-12 homeReportRow">
                <div class="col-sm-2" >{profitData[0]?.cogs}</div>
                <div class="col-sm-2" >{profitData[0]?.shipping_cost}</div>
                <div class="col-sm-2" >{profitData[0]?.number_of_refunds}</div>
                <div class="col-sm-2" >{profitData[0]?.refund_dollars_amount}</div>
                <div class="col-sm-2" >{profitData[0]?.number_of_chargebacks}</div>
                <div class="col-sm-2" >{profitData[0]?.chargebacks_dollars_amount}</div>
              </div>
            </div>
            
            <div className='row'>
              <div className="col-lg-12 homeReportRow  header">
              <div class="col-sm-2" >Declines.Count.Cardknox</div>
              <div class="col-sm-2" >Declines.Rate.Cardknox</div>
              <div class="col-sm-2" >Declines.Count.Bluesnap</div>
              <div class="col-sm-2" >Declines.Rate.Bluesnap</div>
              <div class="col-sm-2" >Declines.Count.Stripe</div>
              <div class="col-sm-2" >Declines.Rate.Stripe</div>
              </div>
              <div className='row'>
                <div className="col-lg-12 homeReportRow">
                <div class="col-sm-2" >{declineData?.cardknox?.count}</div>
                  <div class="col-sm-2">{declineData?.cardknox?.approval_rate}</div>
                  <div class="col-sm-2" >{declineData?.bluesnap?.count}</div>
                  <div class="col-sm-2">{declineData?.bluesnap?.approval_rate}</div>
                  <div class="col-sm-2" >{declineData?.stripe?.count}</div>
                  <div class="col-sm-2" >{declineData?.stripe?.approval_rate}</div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-12 homeReportRow  header">
              <div class="col-sm-2" ></div>
              <div class="col-sm-2" ></div>
              <div class="col-sm-2" >Declines.Count.Banquest</div>
              <div class="col-sm-2" >Declines.Rate.Banquest</div>
              </div>
              <div className='row'>
                <div className="col-lg-12 homeReportRow">
                <div class="col-sm-2" ></div>
                <div class="col-sm-2" ></div>
                <div class="col-sm-2" >{declineData?.banquest?.count}</div>
                  <div class="col-sm-2">{declineData?.banquest?.approval_rate}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      

      
      <h2>Trends Report</h2>

      <div className="row">
        
        <div className="col-lg-16">
        <div class="col-sm-10" >
        
        <div className='FilterDivSection'>
            <label className='startDateStyle_home'>Date</label>
            {/* <label className='step_home'>Data Points</label>
            <label className='range_home'>Step Length</label> */}

            <label className='step_home'>Step Length</label>
            <label className='range_home'>Data Points</label>

            {!REFCBG ? '' :
            <label className='refundStyle_home_G'>Refund Percent</label>
          }
          {!REFCBG ? '' :
            <label className='chargebackStyle_home_G'>CB Percent</label>
          }

  
            <div className="input-bar">
           
              
              <div className="input-bar-item width100">
  
              <DatePicker
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                selected={graphDate}
                className='width142x'
                onChange={(date) => setGraphDate(date)}
                placeholder='Custom Date'
              />
  
              </div>
  
              &nbsp;&nbsp;&nbsp;

            <div className='input-bar-item width100'>
            <select value={rangeType} onChange={(e) => setRangeType(e.target.value)} class="width80x">
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
            </select>

            </div>
  
          &nbsp;&nbsp;&nbsp;

          &nbsp;&nbsp;&nbsp;

              <div className="input-bar-item width100">
  
                <input
                type="number"
                value={numDataPoints}
                min={1}
                max={25}
                class="width70x"
                // onChange={(e) => setNumDataPoints(Number(e.target.value))}
                onChange={handleChangeNumber}
                />
  
              </div>

              &nbsp;&nbsp;&nbsp;

<div className="checkboxDiv">
  <FormGroup>
    <FormControlLabel control={<Checkbox
      ref={REFCBCheckBoxG}
      sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
      name='Estimate Refunds/CBs'
      value={REFCBG}
      defaultChecked={REFCBG}
      onChange={() => setREFCBG(!REFCBG)}
    />}
      label="Estimate Refunds/CBs" />
  </FormGroup>
</div>

{!REFCBG ? '' :

  <div className="input-bar-item">


    <Box width={150}></Box>

    <PrettoSlider

      aria-label="pretto slider"
      defaultValue={0}
      min={0}
      max={15}
      value={RefundG}
      onChange={e => setRefundG(e.target.value)}
      step={1}
      valueLabelDisplay="on"
      marks
    />
  </div>
}

{!REFCBG ? '' :

  <div className="input-bar-item">



    {/* <Box sx={{ m: 3 }} /> */}
    <Box width={150}></Box>
    <PrettoSlider

      aria-label="pretto slider"
      defaultValue={0}
      min={0}
      max={15}
      value={CBG}
      onChange={e => setCBG(e.target.value)}
      step={1}
      valueLabelDisplay="on"
      marks
    />
  </div>
}
                  
  <div className="input-bar-item">
    <button type="button" onClick={runGraph} className="btn btn-primary">Run Graph</button>
  </div>
    
            </div>
  
        </div>
        <div class="col-sm-4" ></div>
        </div>
        <div class="col-sm-4" ></div>
        </div>
        </div>

       
        {/* <div>
        <div className="checkbox-container">
        <label className="checkbox-label checkbox1">
          <input
            type="checkbox"
            checked={showGraph1}
            onChange={() => setShowGraph1(!showGraph1)}
          />
          Show Orders Count (Graph 1)
        </label>

        <label className="checkbox-label checkbox2">
          <input
            type="checkbox"
            checked={showGraph2}
            onChange={() => setShowGraph2(!showGraph2)}
          />
          Show Revenue (Graph 2)
        </label>

        <label className="checkbox-label checkbox3">
          <input
            type="checkbox"
            checked={showGraph3}
            onChange={() => setShowGraph3(!showGraph3)}
          />
          Show Profit (Graph 3)
        </label>
      </div>
        </div> */}
        
      {loaderG && 
      <div className='loaderDiv loaderGraph'>
      <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
      </div>
    }

      {!loaderG &&
      <div>
         <div style={{ width: '90%', height: '500px', margin: '20px 25px auto' }}>
      {/* <div style={{ marginTop: '20px' }}> */}

        
        {chartData && showGraph1 &&
        <><h5 className='center-align'>Order Volume</h5>
        <Line data={chartData} options={chartOptions} />
        </>
        }
      </div>
      {/* <div style={{ marginTop: '20px' }}> */}
      <div style={{ width: '90%', height: '500px', margin: '50px 25px auto' }}>
      {chartDataRP && showGraph2 && 
        <><h5 className='center-align'>Revenue and Profits</h5>
        <Line data={chartDataRP} options={options} />
        </>
        }
      </div>
      {/* <div style={{ marginTop: '20px' }}> */}
      <div style={{ width: '90%', height: '500px', margin: '50px 25px auto' }}>
      {chartDataRP && showGraph3 &&
        <>
      <h5 className='center-align'>Margin</h5>
        {chartDataM && <Line data={chartDataM} options={chartOptions2} />}
        </>
    }
      </div>
      </div>
}
      </section>
      </div>
    
  );


}
export default HomePage;
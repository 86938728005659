function formatNumberWithToFixed(nStr) {
    var decPlcs=2;
    nStr = parseFloat(nStr).toFixed(decPlcs);
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
  }
  
  function roundToFour(num) {
    return +(Math.round(num + "e+4") + "e-4");
  }
  
  function inArrayForString(needle, haystack) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i].toLowerCase() == needle.toLowerCase())
        return true;
    }
    return false;
  }

  
  function replaceNaNInStrings(obj) {
    // Ensure the input is an object
    if (typeof obj !== 'object' || obj === null) {
      console.log("The provided input is not a valid object.");
      return;
    }
  
    // Create a new object to store the updated key-value pairs
    const newObj = {};
  
    // Loop through all keys of the object
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {  // Ensure we are working with the object's own properties
        let value = obj[key];
  
        // If the value is a string, replace part of the string that contains "NaN"
        if (typeof value === 'string') {
          newObj[key] = value.replace(/NaN/g, '0');  // Replace "NaN" with "0"
        } else {
          newObj[key] = value;  // If it's not a string, keep the value as it is
        }
      }
    }
  
    return newObj;
  }
  
  module.exports.formatNumberWithToFixed=formatNumberWithToFixed;
  module.exports.roundToTwo=roundToTwo;
  module.exports.roundToFour=roundToFour;
  module.exports.inArrayForString=inArrayForString;
  module.exports.replaceNaNInStrings=replaceNaNInStrings;
